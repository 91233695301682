import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
/**
 *格式化时间
 *yyyy-MM-dd hh:mm:ss
 */

import _ from 'lodash';
import moment from 'moment';
export function formatDate(time, fmt) {
  if (time === undefined || '') {
    return;
  }
  var date = new Date(time);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}
/*
 * 隐藏用户手机号中间四位
 */
export function hidePhone(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}
export function studyTimeFormat(studyTime) {
  var formattedStudyTime;
  try {
    if (_.includes(studyTime, '-')) {
      formattedStudyTime = moment(studyTime, 'YYYYMMDD-HHmmss').format('YYYY-MM-DD HH:mm:ss');
    } else {
      formattedStudyTime = moment(studyTime, 'YYYYMMDD').format('YYYY-MM-DD');
    }
  } catch (err) {
    formattedStudyTime = studyTime;
  }
  formattedStudyTime = moment(formattedStudyTime, true).isValid() ? formattedStudyTime : studyTime;
  return formattedStudyTime;
}
export function formatDateTime(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD HH:mm';
  var formatedDate = moment(date, true).isValid() ? moment(date).format(format) : date;
  return formatedDate;
}
export function formatVoiceLength(millisecond) {
  return parseInt(millisecond / 1000);
}