import "/var/jenkins_home/workspace/Telezx.medataw.stage.doctor/node_modules/core-js/modules/es.array.iterator.js";
import "/var/jenkins_home/workspace/Telezx.medataw.stage.doctor/node_modules/core-js/modules/es.promise.js";
import "/var/jenkins_home/workspace/Telezx.medataw.stage.doctor/node_modules/core-js/modules/es.object.assign.js";
import "/var/jenkins_home/workspace/Telezx.medataw.stage.doctor/node_modules/core-js/modules/es.promise.finally.js";
// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 设置 js中可以访问 $cdn
Vue.prototype.$cdn = process.env.VUE_APP_CSS_CDN;

// 全局引入按需引入UI库 vant
import '@/plugins/vant';

// plugins
import { VantTelezxUploader } from '@/plugins/TelezxVantUploader';
import TelezxVantMediaPreviewer from '@/plugins/TelezxVantMediaPreviewer';
import TelezxVantCollapse from '@/plugins/TelezxVantCollapse';
import TelezxVantCollapseItem from '@/plugins/TelezxVantCollapseItem';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
Vue.use(VantTelezxUploader);
Vue.use(TelezxVantMediaPreviewer);
Vue.use(TelezxVantCollapse);
Vue.use(TelezxVantCollapseItem);
Vue.use(VueAwesomeSwiper);

// 引入全局样式
import '@/assets/css/index.scss';
import '@/assets/css/uview.scss';
import '@/plugins/TelezxVantUploader/index.scss';
import '@/plugins/TelezxVantMediaPreviewer/index.scss';
import '@/plugins/TelezxVantCollapseItem/index.scss';
import Vconsole from 'vconsole';
if (process.env.NODE_ENV === 'staging') {
  new Vconsole();
}
// new Vconsole()

// 移动端适配
import 'lib-flexible/flexible.js';

// filters
import './filters';
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});