import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * 基础路由
 * @type { *[] }
 */
export var constantRouterMap = [{
  path: '/xgPlayer',
  name: 'XgPlayer',
  component: function component() {
    return import('views/xgPlayer');
  }
}, {
  path: '/',
  name: 'index',
  redirect: function redirect() {
    return '/home';
  }
}, {
  path: '/login',
  name: 'Login',
  component: function component() {
    return import('@/views/login/index');
  },
  meta: {
    title: '登录'
  }
}, {
  path: '/bindMobile',
  name: 'BindMobile',
  component: function component() {
    return import('@/views/login/bindMobile');
  },
  meta: {
    title: '绑定手机号'
  }
}, {
  path: '/wechat',
  name: 'WechatMp',
  component: function component() {
    return import('views/wechat');
  }
}, {
  path: '/home',
  name: 'Home',
  component: function component() {
    return import('views/layouts/index');
  },
  meta: {
    title: "远程智像",
    keepAlive: false
  },
  children: [{
    path: '/home',
    name: 'Home',
    component: function component() {
      return import('@/views/home/index');
    },
    meta: {
      title: '远程智像',
      keepAlive: false,
      showTabBar: true
    }
  }, {
    path: '/search',
    name: 'Search',
    component: function component() {
      return import('@/views/home/search');
    },
    meta: {
      title: '搜索',
      keepAlive: false
    }
  }, {
    path: '/articleList',
    name: 'ArticleList',
    component: function component() {
      return import('@/views/home/articleList');
    },
    meta: {
      title: '文章列表',
      keepAlive: false
    }
  }, {
    path: '/case',
    name: 'Case',
    component: function component() {
      return import('@/views/case/index');
    },
    meta: {
      title: '我的患者',
      keepAlive: true,
      showTabBar: true
    }
  }, {
    path: '/case/group',
    name: 'GroupCases',
    component: function component() {
      return import('@/views/case/group');
    },
    meta: {
      title: '团队患者',
      keepAlive: true
    }
  }, {
    path: '/consultations',
    name: 'Consultation',
    component: function component() {
      return import('@/views/consultations/index');
    },
    meta: {
      title: '患者咨询',
      keepAlive: false,
      showTabBar: true
    }
  }, {
    path: '/consultations/group',
    name: 'GroupConsultation',
    component: function component() {
      return import('@/views/consultations/group');
    },
    meta: {
      title: '群患者咨询',
      keepAlive: false,
      showTabBar: true
    }
  }, {
    path: '/consultations/:consultationId',
    name: 'ConsultationDetail',
    component: function component() {
      return import('@/views/consultations/components/ConsultationDetailTemplate');
    },
    meta: {
      title: '咨询详情',
      keepAlive: false
    }
  }, {
    path: '/order',
    name: 'Order',
    component: function component() {
      return import('@/views/order/index');
    },
    meta: {
      title: '会诊单',
      keepAlive: false,
      showTabBar: true
    }
  }, {
    path: '/mine',
    name: 'Mine',
    component: function component() {
      return import('@/views/mine/index');
    },
    meta: {
      title: '个人中心',
      keepAlive: false,
      showTabBar: true
    }
  }, {
    path: '/diagnosis',
    name: 'Diagnosis',
    component: function component() {
      return import('@/views/diagnosis/index');
    },
    meta: {
      title: '影像诊断',
      keepAlive: false,
      showTabBar: true
    }
  }, {
    path: '/order/specialists',
    name: 'OrderSpecialist',
    component: function component() {
      return import('@/components/OrderTemplate/specialists');
    },
    meta: {
      keepAlive: false
    }
  }, {
    path: '/order/specialists/:specialistId',
    name: 'OrderSpecialistDetail',
    component: function component() {
      return import('@/components/OrderTemplate/specialistDetail');
    },
    meta: {
      keepAlive: false
    }
  }, {
    path: '/order/createOrEdit/:orderId',
    name: 'OrderCreateOrEdit',
    component: function component() {
      return import('@/components/OrderTemplate/createOrEdit');
    },
    meta: {
      keepAlive: false
    }
  }, {
    path: '/order/:orderId',
    name: 'OrderDetail',
    component: function component() {
      return import('@/components/OrderTemplate/index');
    },
    meta: {
      keepAlive: false
    }
  }, {
    path: '/order/:orderId/sup',
    name: 'OrderSup',
    component: function component() {
      return import('@/components/OrderTemplate/activitySup');
    },
    meta: {
      keepAlive: false
    }
  }, {
    path: '/case/create',
    name: 'CaseCreate',
    component: function component() {
      return import('@/views/case/createOrEdit');
    },
    meta: {
      keepAlive: false
    }
  }, {
    path: '/case/share',
    name: 'CaseShare',
    component: function component() {
      return import('views/case/share');
    },
    meta: {
      title: '分享病案',
      keepAlive: false
    }
  }, {
    path: '/case/detail',
    name: 'CaseDetail',
    component: function component() {
      return import('@/views/case/detail/index');
    },
    meta: {
      title: '病案详情',
      keepAlive: true
    }
  }, {
    path: '/case/:caseId',
    name: 'CaseDetailTwo',
    component: function component() {
      return import('@/views/case/detail/index');
    },
    meta: {
      title: '病案详情',
      keepAlive: true
    }
  }, {
    path: '/case/:caseId/collect',
    name: 'CaseCollect',
    component: function component() {
      return import('@/views/case/collect');
    },
    meta: {
      keepAlive: false
    }
  }, {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: function component() {
      return import('@/views/login/forgetPassword');
    },
    meta: {
      title: '忘记密码',
      keepAlive: false
    }
  }, {
    path: '/about',
    name: 'About',
    component: function component() {
      return import('@/views/mine/about');
    },
    meta: {
      title: '关于我们',
      keepAlive: false
    }
  }, {
    path: '/setting',
    name: 'Setting',
    component: function component() {
      return import('@/views/mine/setting');
    },
    meta: {
      title: '设置',
      keepAlive: false
    }
  }, {
    path: '/tenantList',
    name: 'TenantList',
    component: function component() {
      return import('@/views/mine/tenantList');
    },
    meta: {
      title: '设置',
      keepAlive: false
    }
  }, {
    path: '/createTenant',
    name: 'CreateTenant',
    component: function component() {
      return import('@/views/mine/createTenant');
    },
    meta: {
      title: '新建单位',
      keepAlive: false
    }
  }, {
    path: '/userInfo',
    name: 'UserInfo',
    component: function component() {
      return import('@/views/mine/userInfo');
    },
    meta: {
      title: '个人资料',
      keepAlive: false
    }
  }, {
    path: '/wallet',
    name: 'Wallet',
    component: function component() {
      return import('@/views/mine/wallet');
    },
    meta: {
      title: '我的积分',
      keepAlive: false
    }
  }, {
    path: '/walletRecord',
    name: 'WalletRecord',
    component: function component() {
      return import('@/views/mine/walletRecord');
    },
    meta: {
      title: '积分记录',
      keepAlive: false
    }
  }, {
    path: '/walletRecordList',
    name: 'WalletRecordList',
    component: function component() {
      return import('@/views/mine/walletRecordList');
    },
    meta: {
      title: '积分记录',
      keepAlive: false
    }
  }, {
    path: '/infoInput',
    name: 'InfoInput',
    component: function component() {
      return import('@/views/mine/infoInput');
    },
    meta: {
      title: '编辑资料',
      keepAlive: false
    }
  }, {
    path: '/modifyPassword',
    name: 'ModifyPassword',
    component: function component() {
      return import('@/views/mine/modifyPassword');
    },
    meta: {
      title: 'dicom影像',
      keepAlive: false
    }
  }, {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: function component() {
      return import('@/views/mine/resetPassword');
    },
    meta: {
      title: 'dicom影像',
      keepAlive: false
    }
  }, {
    path: '/hospitalInfo',
    name: 'HospitalInfo',
    component: function component() {
      return import('@/views/login/hospitalInfo');
    },
    meta: {
      title: '绑定医院'
    }
  }, {
    path: '/hospitalList',
    name: 'HospitalList',
    component: function component() {
      return import('@/views/hospital/hospitalList');
    },
    meta: {
      title: '医院列表'
    }
  }, {
    path: '/createHospital',
    name: 'CreateHospital',
    component: function component() {
      return import('@/views/hospital/createHospital');
    },
    meta: {
      title: '新建单位'
    }
  }, {
    path: '/searchHospital',
    name: 'SearchHospital',
    component: function component() {
      return import('@/views/hospital/searchHospital');
    },
    meta: {
      title: '搜索医院'
    }
  }, {
    path: '/selectCity',
    name: 'SelectCity',
    component: function component() {
      return import('@/views/hospital/selectCity');
    },
    meta: {
      title: '选择城市'
    }
  }, {
    path: '/avm',
    name: 'AVM',
    component: function component() {
      return import('@/views/avm/index');
    },
    meta: {
      title: '受试者招募'
    }
  }, {
    path: '/avm/list',
    name: 'AVMList',
    component: function component() {
      return import('@/views/avm/list');
    },
    meta: {
      title: 'AVM入组'
    }
  }, {
    path: '/avm/question',
    name: 'AVMQuestion',
    component: function component() {
      return import('@/views/avm/question');
    },
    meta: {
      title: 'AVM入组'
    }
  }, {
    path: '/avm/baseInfo',
    name: 'AVMBaseInfo',
    component: function component() {
      return import('@/views/avm/base-info');
    },
    meta: {
      title: 'AVM入组'
    }
  }, {
    path: '/avm/success',
    name: 'AVMSuccess',
    component: function component() {
      return import('@/views/avm/success');
    },
    meta: {
      title: 'AVM入组'
    }
  }]
}, {
  path: '/dicom',
  name: 'Dicom',
  component: function component() {
    return import('views/layouts/index');
  },
  meta: {
    title: 'DICOM影像',
    keepAlive: false
  },
  children: [{
    path: '/dicom',
    name: 'Dicom',
    component: function component() {
      return import('@/views/dicom/index');
    },
    meta: {
      title: '影像列表',
      keepAlive: false,
      showTabBar: true
    }
  }, {
    path: '/dicom/dicomList',
    name: 'DicomList',
    component: function component() {
      return import('@/views/dicom/dicomList');
    },
    meta: {
      title: 'DICOM影像列表',
      keepAlive: false
    }
  }, {
    path: '/dicom/detail',
    name: 'DicomDetail',
    component: function component() {
      return import('@/views/dicom/detail/index');
    },
    meta: {
      title: 'dicom影像',
      keepAlive: false
    }
  }]
}, {
  path: '/settlement',
  name: 'Settlement',
  component: function component() {
    return import('views/layouts/index');
  },
  meta: {
    title: '结算',
    keepAlive: false
  },
  children: [{
    path: '',
    name: 'SettlementList',
    component: function component() {
      return import('@/views/settlement');
    },
    meta: {
      title: '结算列表',
      keepAlive: false
    }
  }, {
    path: 'new-settlement',
    name: 'NewSettlement',
    component: function component() {
      return import('@/views/settlement/components/NewSettlementTemplate');
    },
    meta: {
      title: '新建结算',
      keepAlive: false
    }
  }, {
    path: ':settlementId',
    name: 'SettlementDetail',
    component: function component() {
      return import('@/views/settlement/components/SettlementDetailTemplate');
    },
    meta: {
      title: '结算详情',
      keepAlive: false
    }
  }]
}, {
  path: '/followupCard',
  name: 'FollowupCard',
  component: function component() {
    return import('views/layouts/index');
  },
  meta: {
    title: '随访卡',
    keepAlive: false
  },
  children: [{
    path: 'detail',
    name: 'FollowupCardDetail',
    component: function component() {
      return import('@/views/followupCard');
    },
    meta: {
      title: '随访卡展示',
      keepAlive: false
    }
  }, {
    path: 'demo',
    name: 'FollowupCardDemo',
    component: function component() {
      return import('@/views/followupCard/demo');
    },
    meta: {
      title: '随访卡示例',
      keepAlive: false
    }
  },
  // TIP: 这个路由是为了解决未登录状态下跳转到示例页面保存登录状态下的路由记录用的
  {
    path: 'demo-logined',
    name: 'FollowupCardDemoLogined',
    component: function component() {
      return import('@/views/followupCard/demo');
    },
    meta: {
      title: '随访卡示例',
      keepAlive: false
    }
  }]
}, {
  path: '/groups',
  name: 'Group',
  component: function component() {
    return import('views/layouts/index');
  },
  meta: {
    title: '智像群',
    keepAlive: false
  },
  children: [{
    path: '',
    name: 'GroupList',
    component: function component() {
      return import('@/views/group');
    },
    meta: {
      title: '团队(智像群)列表',
      keepAlive: false
    }
  }, {
    path: ':groupId/invite',
    name: 'GroupInvite',
    component: function component() {
      return import('@/views/group/invite');
    },
    meta: {
      title: '智像群邀请',
      keepAlive: false
    }
  }, {
    path: ':groupId/members',
    name: 'GroupMembers',
    component: function component() {
      return import('@/views/group/members');
    },
    meta: {
      title: '智像群成员',
      keepAlive: false
    }
  }, {
    path: ':groupId/applicants',
    name: 'GroupApplicants',
    component: function component() {
      return import('@/views/group/applicants');
    },
    meta: {
      title: '申请列表',
      keepAlive: false
    }
  }, {
    path: 'operate',
    name: 'GroupOpearte',
    component: function component() {
      return import('@/views/group/operate');
    },
    meta: {
      title: '智像群操作',
      keepAlive: false
    }
  }, {
    path: 'join',
    name: 'GroupJoin',
    component: function component() {
      return import('@/views/group/join');
    },
    meta: {
      title: '加入智像群',
      keepAlive: false
    }
  }]
}];