import "core-js/modules/es.array.concat.js";
import moment from 'moment';
import { Base64 } from 'js-base64';
import db from '@/utils/localStorage';
export function getToken() {
  return db.get('TOKEN', '');
}
export function getRefreshToken() {
  return db.get('REFRESH_TOKEN', '');
}
export function getExpireTime() {
  return db.get('TOKEN_EXPIRE_TIME', 0);
}
export function getTenant() {
  return db.get('TENANT', '');
}
export function getTenantCode() {
  var tenantInfo = db.get('TENANT_INFO', '');
  return tenantInfo.tenantCode;
}
export function getUserId() {
  var userInfo = db.get('USER', '');
  return userInfo.id;
}
export function getAuditState() {
  var userInfo = db.get('USER', '');
  return userInfo.auditState;
}
export function getHttpHeader() {
  var clientId = 'telezx_hospital';
  var clientSecret = 'telezx_hospital_secret';
  return {
    tenant: db.get('TENANT', ''),
    Authorization: "Basic ".concat(Base64.encode("".concat(clientId, ":").concat(clientSecret))),
    token: 'Bearer ' + db.get('TOKEN', '')
  };
}
export function isTokenExpired() {
  var expiredTime = getExpireTime();
  if (expiredTime) {
    var nowTime = new Date().getTime();
    expiredTime = moment(expiredTime, 'YYYY-MM-DD HH:mm:ss').valueOf();
    return expiredTime - nowTime < 0;
  }
  return false;
}