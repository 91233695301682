/*
* @Author: Vincent
* @Date:   2021-01-28 14:59:54
* @Last Modified by:   Vincent
* @Last Modified time: 2021-01-28 16:09:58
*/

import { judgeClient } from '@/utils/common';
export default {
  namespaced: true,
  state: {
    signUrl: ''
  },
  mutations: {
    setSignUrl: function setSignUrl(state, val) {
      var client = judgeClient();
      if (client === 'IOS' && state.signUrl !== '') {
        return;
      }
      state.signUrl = val;
    }
  }
};