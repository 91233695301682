import _typeof from "/var/jenkins_home/workspace/Telezx.medataw.stage.doctor/node_modules/@babel/runtime/helpers/esm/typeof.js";
import { judgeClient } from '@/utils/common';
export default {
  name: 'App',
  mounted: function mounted() {
    this.$nextTick(function () {
      var client = judgeClient();
      if (client === 'Android') {
        //android禁止微信浏览器调整字体大小
        if ((typeof WeixinJSBridge === "undefined" ? "undefined" : _typeof(WeixinJSBridge)) == 'object' && typeof WeixinJSBridge.invoke == 'function') {
          this.handleFontSize();
        } else {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.handleFontSize, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.handleFontSize);
            document.attachEvent('onWeixinJSBridgeReady', this.handleFontSize);
          }
        }
      }
    });
  },
  methods: {
    handleFontSize: function handleFontSize() {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke('setFontSizeCallback', {
        fontSize: 0
      });

      // 重写设置网页字体大小的事件
      WeixinJSBridge.on('menu:setfont', function () {
        WeixinJSBridge.invoke('setFontSizeCallback', {
          fontSize: 0
        });
      });
    }
  }
};