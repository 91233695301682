/*
* @Author: Vincent
* @Date:   2021-01-28 10:42:07
* @Last Modified by:   Vincent
* @Last Modified time: 2021-01-28 10:43:27
*/

import axiosApi from '@/utils/request';
var API_PREFIX = '/authority/dictionaryItem';
export var getDictByKeys = function getDictByKeys(data) {
  return axiosApi({
    method: 'GET',
    url: "".concat(API_PREFIX, "/codes"),
    data: data
  });
};