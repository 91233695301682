import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import _ from 'lodash';
import moment from 'moment';
var db = {
  save: function save(key, value) {
    var expirationTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var projectName = process.env.VUE_APP_PROJECT_NAME;
    var data = {
      value: value
    };
    if (!_.isEmpty(expirationTime)) {
      data = _.assign(data, {
        expiredAt: moment().add(expirationTime).format('X')
      });
    }
    localStorage.setItem(projectName + "_" + key, JSON.stringify(data));
    // localStorage.setItem(projectName + "_" + key, JSON.stringify(value))
  },
  get: function get(key) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var projectName = process.env.VUE_APP_PROJECT_NAME;
    try {
      // return JSON.parse(localStorage.getItem(projectName + "_" + key)) || defaultValue
      var data = JSON.parse(localStorage.getItem(projectName + "_" + key)) || defaultValue;
      // 兼容老版本localstorage
      if (_.isObject(data)) {
        if (data && data.expiredAt) {
          var currentTimestamp = moment().format('X');
          if (currentTimestamp > data.expiredAt) {
            localStorage.removeItem(projectName + '_' + key);
            return null;
          } else {
            return data.value;
          }
        } else {
          return data.value;
        }
      } else {
        return data;
      }
    } catch (err) {
      return defaultValue;
    }
  },
  remove: function remove(key) {
    var projectName = process.env.VUE_APP_PROJECT_NAME;
    localStorage.removeItem(projectName + "_" + key);
  },
  clear: function clear() {
    // localStorage.clear()

    // 为了使 zuihou-admin-ui 和 zuihou-ui 能在同一个域下 独立使用， 清除缓存时，需要区分来

    var projectName = process.env.VUE_APP_PROJECT_NAME;
    var projectOpsName = process.env.VUE_APP_PROJECT_OPS_NAME;
    for (var key in localStorage) {
      if (key.indexOf(projectName) !== -1) {
        localStorage.removeItem(key);
      } else {
        // 删除既不属于OPS又不属于hospital的localstorage
        if (key.indexOf(projectOpsName) < 0) {
          localStorage.removeItem(key);
        }
      }
    }
  }
};
export default db;