import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import _objectSpread from "/var/jenkins_home/workspace/Telezx.medataw.stage.doctor/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import axios from 'axios';
import store from '@/store';
import { Base64 } from 'js-base64';
import db from '@/utils/localStorage';
import { getToken, getRefreshToken, getExpireTime, getTenantCode } from '@/utils/auth';
import moment from 'moment';

// 是否有请求再刷新token
window.isRefreshing = false;
// 被挂起的请求数组
var subscribesArr = [];
var httpServer = function httpServer(opts) {
  var publicParams = {
    ts: Date.now()
  };
  var method = opts.method.toUpperCase();
  var instance = axios.create({
    baseURL: "".concat(process.env.VUE_APP_API_DOMAIN).concat(process.env.VUE_APP_API_PREFIX),
    timeout: 10000,
    responseType: opts.responseType || 'json'
  });

  // 请求添加条件，如token
  instance.interceptors.request.use(function (config) {
    var isToken = config.headers['X-isToken'] === false ? config.headers['X-isToken'] : true;
    var token = getToken();
    var clientId = 'telezx_doctor';
    var clientSecret = 'telezx_doctor_secret';
    if (getTenantCode()) {
      config.headers.tenant = Base64.encode(getTenantCode());
    }
    config.headers['Authorization'] = "Basic ".concat(Base64.encode("".concat(clientId, ":").concat(clientSecret)));
    if (token && isToken) {
      config.headers.token = 'Bearer ' + token;
      if (isTokenExpired()) {
        // 如果token已经过期，尝试用refreshToken去刷新token
        if (!window.isRefreshing) {
          window.isRefreshing = true;
          refreshToken().then(function (response) {
            window.isRefreshing = false;
            var res = response.data;
            if (res.isSuccess) {
              var data = res.data;
              var _token = data.token,
                _refreshToken = data.refreshToken,
                expiration = data.expiration;
              saveLoginData(_token, _refreshToken, expiration);
              // 重新发起挂起的请求
              reloadSubscribesArr(_token);
            } else {
              subscribesArr = [];
              directLogin();
            }
          }).catch(function (err) {
            console.error(err);
            window.isRefreshing = false;
            subscribesArr = [];
            directLogin();
          });
        }
        // 如果token已经过期，挂起请求
        var retry = new Promise(function (resolve) {
          subscribeTokenRefresh(function (newToken) {
            config.headers.token = 'Bearer ' + newToken;
            resolve(config);
          });
        });
        return retry;
      }
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  // 接口返回处理
  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });
  var httpDefaultOpts = {
    method: method,
    url: opts.url,
    isHiddenLoading: false || opts.isHiddenLoading !== undefined && opts.isHiddenLoading
  };
  if (opts['meta']) {
    httpDefaultOpts.headers = opts['meta'];
  }
  var dataRequest = ['PUT', 'POST', 'PATCH'];
  if (dataRequest.includes(method)) {
    httpDefaultOpts.data = opts.data || {};
  } else {
    httpDefaultOpts.params = _objectSpread(_objectSpread({}, publicParams), opts.data || {});
  }

  // formData转换
  if (opts.formData) {
    httpDefaultOpts.transformRequest = [function (data) {
      if (data instanceof FormData) {
        return data;
      }
      var formData = new FormData();
      if (data) {
        Object.entries(data).forEach(function (item) {
          formData.append(item[0], item[1]);
        });
      }
      return formData;
    }];
  }
  if (opts.isShowProgress) {
    httpDefaultOpts.onUploadProgress = opts.onUploadProgress;
  }
  if (!httpDefaultOpts.isHiddenLoading) {
    _Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true
    });
  }
  var promise = new Promise(function (resolve, reject) {
    instance(httpDefaultOpts).then(function (response) {
      if (!httpDefaultOpts.isHiddenLoading) {
        _Toast.clear();
      }
      handleSuccess(response, resolve, opts);
    }).catch(function (error) {
      if (!httpDefaultOpts.isHiddenLoading) {
        _Toast.clear();
      }
      handleError(error, reject, opts);
    });
  });
  return promise;
};
function handleError(error, reject, opts) {
  var isAlert = opts.custom ? opts.custom['isAlert'] : true;
  if (isAlert) {
    if (error.code === 'ECONNABORTED') {
      _Toast.fail('请求超时');
    } else if (error.response && error.response.data.code === 40005) {
      _Dialog.alert({
        title: '您尚未登录，请前往登录!'
      }).then(function () {
        // 保存当前路由后调整到登录页面
        var currentUrl = store.getters.currentUrl;
        debugger;
        store.commit('app/SET_TARGET_URL', currentUrl);
        directLogin();
      });
    } else if (error.response && error.response.data) {
      var msg = error.response.data.msg || '';
      _Toast.fail(msg);
    } else if (error.msg) {
      _Toast.fail(error.msg);
    }
  }
  reject(error);
}
function handleSuccess(res, resolve, opts) {
  var isAlert = opts.custom ? opts.custom['isAlert'] : true;
  if (res.data && res.data.isError) {
    // 未登录
    if (res.data.code === 40000 || res.data.code === 40001 || res.data.code === 40002 || res.data.code === 40003 || res.data.code === 40005 || res.data.code === 40006 || res.data.code === 40008) {
      _Dialog.alert({
        title: '请先登录!'
      }).then(function () {
        directLogin();
      });
    } else {
      if (res.data.code === 40163) {
        isAlert = false;
      }
      if (isAlert) {
        if (res.data.code !== 1000 && res.data.code !== 1004) {
          _Toast.fail(res.msg || res.data && res.data.msg);
        }
      }
    }
  }
  resolve(res);
}
var refreshService = axios.create({
  baseURL: "".concat(process.env.VUE_APP_API_DOMAIN).concat(process.env.VUE_APP_API_PREFIX),
  timeout: 10000,
  responseType: 'json'
});
function refreshToken() {
  var refreshToken = getRefreshToken();
  var clientId = 'telezx_hospital';
  var clientSecret = 'telezx_hospital_secret';
  return new Promise(function (resolve, reject) {
    refreshService.post("/authority/v2/users/login", {
      refreshToken: refreshToken,
      grantType: 'refresh_token',
      // tenant: Base64.encode(getTenantCode())
      tenantCode: getTenantCode()
    }, {
      headers: {
        'Authorization': "Basic ".concat(Base64.encode("".concat(clientId, ":").concat(clientSecret)))
      }
    }).then(function (response) {
      resolve(response);
    }, function (err) {
      reject(err);
    });
  });
}
function saveLoginData(token, refreshToken, expiration) {
  store.commit("account/setToken", token);
  store.commit("account/setRefreshToken", refreshToken);
  store.commit("account/setExpireTime", expiration);
}
// push所有请求到数组中
function subscribeTokenRefresh(cb) {
  subscribesArr.push(cb);
}

// 用新的token发起请求
function reloadSubscribesArr(token) {
  subscribesArr.map(function (cb) {
    return cb(token);
  });
}

// 重定向到login页面
function directLogin() {
  db.remove('TOKEN');
  db.remove('USER');
  db.remove('TOKEN_EXPIRE_TIME');
  db.remove('TENANT');
  db.remove('REFRESH_TOKEN');
  db.remove('TENANT_INFO');
  window.location.hash = '/login';
}
function isTokenExpired() {
  var expiredTime = getExpireTime();
  if (expiredTime) {
    var nowTime = new Date().getTime();
    expiredTime = moment(expiredTime, 'YYYY-MM-DD HH:mm:ss').valueOf();
    return (expiredTime - nowTime) / 1000 < 30; //10 * 60
  }
  return false;
}
export default httpServer;