"use strict";

require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');
exports.__esModule = true;
exports.install = install;
exports.default = exports.version = void 0;
var _uploader = _interopRequireDefault(require('./main'));
exports.VantTelezxUploader = _uploader.default;
var version = '0.0.1';
exports.version = version;
function install(Vue) {
  var components = [_uploader.default];
  components.forEach(function (item) {
    if (item.install) {
      Vue.use(item);
    } else if (item.name) {
      Vue.component(item.name, item);
    }
  });
}
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
var _default = {
  install: install,
  version: version
};
exports.default = _default;