import "vant/es/notice-bar/style";
import _NoticeBar from "vant/es/notice-bar";
import "vant/es/swipe-cell/style";
import _SwipeCell from "vant/es/swipe-cell";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/switch/style";
import _Switch from "vant/es/switch";
import "vant/es/skeleton/style";
import _Skeleton from "vant/es/skeleton";
import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "vant/es/grid/style";
import _Grid from "vant/es/grid";
import "vant/es/grid-item/style";
import _GridItem from "vant/es/grid-item";
import "vant/es/collapse-item/style";
import _CollapseItem from "vant/es/collapse-item";
import "vant/es/collapse/style";
import _Collapse from "vant/es/collapse";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/badge/style";
import _Badge from "vant/es/badge";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/col/style";
import _Col from "vant/es/col";
import "vant/es/row/style";
import _Row from "vant/es/row";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/divider/style";
import _Divider from "vant/es/divider";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/sticky/style";
import _Sticky from "vant/es/sticky";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/calendar/style";
import _Calendar from "vant/es/calendar";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/pull-refresh/style";
import _PullRefresh from "vant/es/pull-refresh";
import "vant/es/checkbox-group/style";
import _CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/share-sheet/style";
import _ShareSheet from "vant/es/share-sheet";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/dropdown-item/style";
import _DropdownItem from "vant/es/dropdown-item";
import "vant/es/dropdown-menu/style";
import _DropdownMenu from "vant/es/dropdown-menu";
// 按需全局引入 vant组件
import Vue from 'vue';
Vue.use(_DropdownMenu);
Vue.use(_DropdownItem);
Vue.use(_Picker);
Vue.use(_CellGroup);
Vue.use(_Radio);
Vue.use(_ShareSheet);
Vue.use(_RadioGroup);
Vue.use(_CheckboxGroup);
Vue.use(_PullRefresh);
Vue.use(_Checkbox);
Vue.use(_Radio);
Vue.use(_Button);
Vue.use(_Cell);
Vue.use(_Calendar);
Vue.use(_Icon);
Vue.use(_List);
Vue.use(_Tabs);
Vue.use(_Tab);
Vue.use(_Sticky);
Vue.use(_Image);
Vue.use(_Form);
Vue.use(_Divider);
Vue.use(_Field);
Vue.use(_Search);
Vue.use(_Toast);
Vue.use(_Dialog);
Vue.use(_Popup);
Vue.use(_Tabbar).use(_TabbarItem);
Vue.use(_NavBar);
Vue.use(_Card);
Vue.use(_Tag);
Vue.use(_Row);
Vue.use(_Col);
Vue.use(_Icon);
Vue.use(_ActionSheet);
Vue.use(_Empty);
Vue.use(_Badge);
Vue.use(_Overlay);
Vue.use(_Collapse);
Vue.use(_CollapseItem);
Vue.use(_Grid).use(_GridItem);
Vue.use(_ImagePreview);
Vue.use(_Skeleton);
Vue.use(_Switch);
Vue.use(_Loading);
Vue.use(_SwipeCell);
Vue.use(_NoticeBar);