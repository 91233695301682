"use strict";

var _ = require('lodash');
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _vue = _interopRequireDefault(require("vue"));
var _MediaPreview = _interopRequireDefault(require("./MediaPreview"));
var _utils = require("vant/lib/utils");
var instance;
var defaultConfig = {
  loop: true,
  value: true,
  medias: [],
  maxZoom: 3,
  minZoom: 1 / 3,
  onClose: null,
  onChange: null,
  className: '',
  showIndex: true,
  closeable: false,
  closeIcon: 'clear',
  asyncClose: false,
  getContainer: 'body',
  startPosition: 0,
  swipeDuration: 300,
  showIndicators: false,
  closeOnPopstate: true,
  closeIconPosition: 'top-right'
};
var initInstance = function initInstance() {
  var extraOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  instance = new (_vue.default.extend(_MediaPreview.default))({
    el: document.createElement('div')
  });
  document.body.appendChild(instance.$el);
  instance.$on('change', function (index) {
    if (instance.onChange) {
      instance.onChange(index);
    }
  });
  instance.$on('scale', function (data) {
    if (instance.onScale) {
      instance.onScale(data);
    }
  });
  instance.$on('videoPlay', function (data) {
    if (!_.isEmpty(extraOptions)) {
      return extraOptions.handleVideoPlay(data);
    }
  });
};
var MediaPreview = function MediaPreview(medias) {
  var startPosition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var extraOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (startPosition === void 0) {
    startPosition = 0;
  }

  /* istanbul ignore if */
  if (_utils.isServer) {
    return;
  }
  if (!instance) {
    initInstance(extraOptions);
  }
  var options = Array.isArray(medias) ? {
    medias: medias,
    startPosition: startPosition
  } : medias;
  (0, _extends2.default)(instance, defaultConfig, options);
  instance.$once('input', function (show) {
    instance.value = show;
  });
  instance.$once('closed', function () {
    instance.medias = [];
  });
  if (options.onClose) {
    instance.$off('close');
    instance.$once('close', options.onClose);
  }
  return instance;
};
MediaPreview.Component = _MediaPreview.default;
MediaPreview.install = function () {
  _vue.default.use(_MediaPreview.default);
};
var _default = MediaPreview;
exports.default = _default;