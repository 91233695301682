import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.iterator.js";
/*
* @Author: Vincent
* @Date:   2021-01-27 15:01:01
* @Last Modified by:   Vincent
* @Last Modified time: 2021-09-16 14:51:34
*/

import _ from 'lodash';
import { getDictByKeys } from '@/api/dictionaryItem';
export function judgeClient() {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //判断是否是 android终端
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端

  if (isAndroid) {
    return 'Android';
  } else if (isIOS) {
    return 'IOS';
  } else {
    return 'PC';
  }
}
export function isInWechat() {
  var ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == 'micromessenger' || ua.match(/_SQ_/i) == '_sq_';
}
export var initDicts = function initDicts() {
  var dicts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var keys = _.keys(dicts) || [];
  return new Promise(function (resolve) {
    if (keys.length === 0) {
      return resolve({});
    }
    return getDictByKeys({
      codes: keys
    }).then(function (response) {
      var res = response.data;
      if (res.isSuccess) {
        _.map(keys, function (key) {
          dicts[key] = res.data[key] || {};
        });
        return resolve(_.cloneDeep(res.data));
      } else {
        return resolve({});
      }
    });
  });
};
export var getUrlParam = function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
};
export function randomNum(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [];
  radix = radix || chars.length;
  if (len) {
    // Compact form
    for (var i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (var _i = 0; _i < 36; _i++) {
      if (!uuid[_i]) {
        r = 0 | Math.random() * 16;
        uuid[_i] = chars[_i === 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }
  return uuid.join('') + new Date().getTime();
}
export var formatCrossOriginUrl = function formatCrossOriginUrl(url) {
  var rawUrl = _.cloneDeep(url);
  if (!_.isEmpty(rawUrl)) {
    rawUrl = _.replace(rawUrl, 'oss.stage.telezx.com', 'm.stage.telezx.com');
    rawUrl = _.replace(rawUrl, 'oss.telezx.com', 'm.telezx.com');
  }
  return rawUrl;
};