import db from '@/utils/localStorage';
export default {
  namespaced: true,
  state: {
    refreshToken: db.get('REFRESH_TOKEN'),
    token: db.get('TOKEN', ''),
    code: db.get('CODE', ''),
    tenant: db.get('TENANT', ''),
    expireTime: db.get('TOKEN_EXPIRE_TIME', 0),
    user: db.get('USER'),
    userProfile: db.get('USER_PROFILE'),
    permissions: db.get('PERMISSIONS') || [],
    routes: db.get('USER_ROUTER') || [],
    tenantType: db.get('TENANT_TYPE') || '',
    tenantInfo: db.get('TENANT_INFO') || {}
  },
  mutations: {
    setTenant: function setTenant(state, val) {
      // debugger
      db.save('TENANT', val);
      state.accessToken = val;
    },
    setCode: function setCode(state, val) {
      // debugger
      db.save('CODE', val);
      state.code = val;
    },
    setRefreshToken: function setRefreshToken(state, val) {
      db.save('REFRESH_TOKEN', val);
      state.refreshToken = val;
    },
    setToken: function setToken(state, val) {
      // debugger
      db.save('TOKEN', val);
      state.token = val;
    },
    setExpireTime: function setExpireTime(state, val) {
      db.save('TOKEN_EXPIRE_TIME', val);
      state.expireTime = val;
    },
    setUser: function setUser(state, val) {
      db.save('USER', val);
      state.user = val;
    },
    setUserProfile: function setUserProfile(state, val) {
      db.save('USER_PROFILE', val);
      state.userProfile = val;
    },
    resetUserProfile: function resetUserProfile(state, val) {
      db.save('USER_PROFILE', val);
      state.userProfile = val;
    },
    setPermissions: function setPermissions(state, val) {
      db.save('PERMISSIONS', val);
      state.permissions = val;
    },
    setRoutes: function setRoutes(state, val) {
      db.save('USER_ROUTER', val);
      state.routes = val;
    },
    setTenantType: function setTenantType(state, val) {
      db.save('TENANT_TYPE', val);
      state.tenantType = val;
    },
    setTenantInfo: function setTenantInfo(state, val) {
      db.save('TENANT_INFO', val);
      state.tenantInfo = val;
    }
  },
  actions: {
    setTenant: function setTenant(_ref, value) {
      var commit = _ref.commit;
      commit('setTenant', value);
    },
    setCode: function setCode(_ref2, value) {
      var commit = _ref2.commit;
      commit('setCode', value);
    },
    setRefreshToken: function setRefreshToken(_ref3, value) {
      var commit = _ref3.commit;
      commit('setRefreshToken', value);
    },
    setToken: function setToken(_ref4, value) {
      var commit = _ref4.commit;
      commit('setToken', value);
    },
    setExpireTime: function setExpireTime(_ref5, value) {
      var commit = _ref5.commit;
      commit('setExpireTime', value);
    },
    setUser: function setUser(_ref6, value) {
      var commit = _ref6.commit;
      commit('setUser', value);
    },
    setUserProfile: function setUserProfile(_ref7, value) {
      var commit = _ref7.commit;
      commit('setUserProfile', value);
    },
    resetUserProfile: function resetUserProfile(_ref8) {
      var commit = _ref8.commit;
      commit('setUserProfile', {});
    },
    setPermissions: function setPermissions(_ref9, value) {
      var commit = _ref9.commit;
      commit('setPermissions', value);
    },
    setRoutes: function setRoutes(_ref10, value) {
      var commit = _ref10.commit;
      commit('setRoutes', value);
    },
    setTenantType: function setTenantType(_ref11, value) {
      var commit = _ref11.commit;
      commit('setTenantType', value);
    },
    setTenantInfo: function setTenantInfo(_ref12, value) {
      var commit = _ref12.commit;
      commit('setTenantInfo', value);
    }
  }
};