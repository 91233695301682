import db from '@/utils/localStorage';
var state = {
  userName: '',
  currentUrl: '',
  targetUrl: '',
  homeTabIndex: 0,
  searchContent: '',
  isIncludeHomePath: false,
  searchHistories: db.get('SEARCH_HISTORIES') || [],
  searchHistoriesResult: db.get('SEARCH_HISTORIES_RESULT') || []
};
var mutations = {
  SET_USER_NAME: function SET_USER_NAME(state, name) {
    state.userName = name;
  },
  SET_CURRENT_URL: function SET_CURRENT_URL(state, url) {
    state.currentUrl = url;
  },
  SET_TARGET_URL: function SET_TARGET_URL(state, url) {
    state.targetUrl = url;
  },
  SET_HOME_TAB_INDEX: function SET_HOME_TAB_INDEX(state, index) {
    state.homeTabIndex = index;
  },
  SET_SEARCH_CONTENT: function SET_SEARCH_CONTENT(state, content) {
    state.searchContent = content;
  },
  SET_HOME_BUTTON_STATUS: function SET_HOME_BUTTON_STATUS(state, val) {
    state.isIncludeHomePath = val;
    db.save('HOME_BUTTON_STATUS', val);
  },
  SET_SEARCH_HISTORIES: function SET_SEARCH_HISTORIES(state, val) {
    state.searchHistories = val;
    db.save('SEARCH_HISTORIES', val);
  },
  SET_SEARCH_HISTORIES_RESULT: function SET_SEARCH_HISTORIES_RESULT(state, val) {
    state.searchHistoriesResult = val;
    db.save('SEARCH_HISTORIES_RESULT', val);
  }
};
var actions = {
  // 设置name
  setUserName: function setUserName(_ref, name) {
    var commit = _ref.commit;
    commit('SET_USER_NAME', name);
  },
  setCurrentUrl: function setCurrentUrl(_ref2, url) {
    var commit = _ref2.commit;
    commit('SET_CURRENT_URL', url);
  },
  setTargetUrl: function setTargetUrl(_ref3, url) {
    var commit = _ref3.commit;
    commit('SET_TARGET_URL', url);
  },
  setHomeTabIndex: function setHomeTabIndex(_ref4, index) {
    var commit = _ref4.commit;
    commit('SET_HOME_TAB_INDEX', index);
  },
  setSearchContent: function setSearchContent(_ref5, content) {
    var commit = _ref5.commit;
    commit('SET_SEARCH_CONTENT', content);
  },
  setSearchHistories: function setSearchHistories(_ref6, content) {
    var commit = _ref6.commit;
    commit('SET_SEARCH_HISTORIES', content);
  },
  setSearchHistoriesResult: function setSearchHistoriesResult(_ref7, content) {
    var commit = _ref7.commit;
    commit('SET_SEARCH_HISTORIES_RESULT', content);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};