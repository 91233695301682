import db from '@/utils/localStorage';
export default {
  namespaced: true,
  state: {
    stash: db.get('CASE_STASH') || {}
  },
  mutations: {
    setStash: function setStash(state, val) {
      db.save('CASE_STASH', val);
      state.stash = val;
    }
  }
};