var getters = {
  user: function user(state) {
    return state.account.user;
  },
  tenantInfo: function tenantInfo(state) {
    return state.account.tenantInfo;
  },
  userName: function userName(state) {
    return state.app.userName;
  },
  currentUrl: function currentUrl(state) {
    return state.app.currentUrl;
  },
  targetUrl: function targetUrl(state) {
    return state.app.targetUrl;
  },
  homeTabIndex: function homeTabIndex(state) {
    return state.app.homeTabIndex;
  },
  searchContent: function searchContent(state) {
    return state.app.searchContent;
  },
  signUrl: function signUrl(state) {
    return state.signature.signUrl;
  },
  caseStash: function caseStash(state) {
    return state.case.stash;
  },
  isIncludeHomePath: function isIncludeHomePath(state) {
    return state.app.isIncludeHomePath;
  },
  searchHistories: function searchHistories(state) {
    return state.app.searchHistories;
  },
  searchHistoriesResult: function searchHistoriesResult(state) {
    return state.app.searchHistoriesResult;
  }
};
export default getters;