import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.search.js";
import Vue from 'vue';
import _ from 'lodash';
import Router from 'vue-router';
import { judgeClient } from '@/utils/common';
import { constantRouterMap } from './router.config.js';
import store from '@/store/index';
import { getToken, getAuditState } from '@/utils/auth';
var originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
Vue.use(Router);
var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRouterMap
  });
};
var router = createRouter();
var whiteList = ['/home', '/login', '/bindMobile', '/hospitalInfo', '/createHospital', '/hospitalList', '/searchHospital', '/selectCity', '/forgetPassword', '/forgetPasswordSubmit', '/wechat'
// '/followupCard/demo'
];
router.beforeEach(function (to, from, next) {
  var client = judgeClient();

  // if (!isInWechat() && client !== 'PC') {
  //   if (to.path !== '/wechat') {
  //     return next({ path: '/wechat', replace: true })
  //   } else {
  //      return next()
  //   }
  // }

  if (whiteList.indexOf(to.path) == -1) {
    store.commit('app/SET_CURRENT_URL', to.fullPath);
  }
  if (isIncludeHomeRoute(to.path)) {
    store.commit('app/SET_HOME_BUTTON_STATUS', true);
  }
  if (client === 'IOS') {
    var signUrl = location.href.split(' ')[0];
    store.commit('signature/setSignUrl', signUrl);
  }
  if (whiteList.indexOf(to.path) !== -1) {
    if (to.path === '/home') {
      var auditState = getAuditState();
      if (auditState === 'PENDING' || auditState === 'REJECTED') {
        // 如果已经有token且验证不通过,则返回登录页
        return next({
          path: '/login',
          replace: true
        });
      }
    }
    return next();
  }
  var token = getToken();
  if (!token) {
    var reg = /^\/case\/[\d]+$/;
    if (to.path === '/case/detail' || reg.test(to.path)) {
      var code = getWxCode();
      if (code) {
        return next({
          path: '/login',
          replace: true
        });
      }
      return next();
    }
    var targetUrl = store.getters.targetUrl;
    if (_.isEmpty(targetUrl)) {
      store.commit('app/SET_TARGET_URL', to.fullPath);
    }
    return next({
      path: '/login',
      replace: true
    });
  } else {
    var _auditState = getAuditState();
    if (_auditState === 'PENDING' || _auditState === 'REJECTED') {
      // 如果已经有token且验证不通过,则返回登录页
      return next({
        path: '/login',
        replace: true
      });
    }
    // debugger
    var _targetUrl = store.getters.targetUrl;
    if (!_.isEmpty(_targetUrl)) {
      store.commit('app/SET_TARGET_URL', null);
      return next({
        path: _targetUrl,
        replace: true
      });
    } else {
      return next();
    }
  }
});
var getWxCode = function getWxCode() {
  var reg = new RegExp("(^|&)" + 'code' + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
};
var isIncludeHomeRoute = function isIncludeHomeRoute(path) {
  var homePath = ['/home'];
  var result = _.includes(homePath, path);
  return result;
};
export default router;